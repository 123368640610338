import React from 'react';
import img1 from '../img/Illegal.png';

const Main = () => (
  <main className="home">
    <img src={img1} alt="Kein Mensch ist illegal" />
  </main>
);

export default Main;
